
import { ogImageModifiers } from '@/utils/seo'

export default {
  name: 'DefaultLayout',
  head() {
    return {
      link: [
        {
          rel: 'canonical',
          href: 'https://www.xai-studio.de' + this.$route.path,
        },
      ],
      meta: [
        {
          hid: 'og:url',
          property: 'og:url',
          content: 'https://www.xai-studio.de' + this.$route.path,
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content:
            'https://www.xai-studio.de' +
            this.$img('/og/xai-studio-logo.png', ogImageModifiers),
        },
      ],
    }
  },
}
