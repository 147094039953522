export const createSEOMeta = (data) => {
  const baseTags = [
    { hid: 'description', name: 'description', content: data.description },
    { hid: 'og:title', property: 'og:title', content: data.title },
    { hid: 'og:type', property: 'og:type', content: 'website' },
    {
      hid: 'og:description',
      property: 'og:description',
      content: data.description,
    },
  ]

  if (data.image) {
    baseTags.push({
      hid: 'og:image',
      property: 'og:image',
      content: 'https://www.xai-studio.de' + data.image,
    })
  }

  return baseTags
}

export const ogImageModifiers = {
  width: 1200,
  height: 630,
  format: 'png',
  fit: 'inside',
}
